import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import Style from 'styles/components/comachicart/cmp.module.scss';

const Comparision = () => {
  return (
    <section className={classNames(Style.cmp)}>
      <h2 className={classNames(Style.catch)}>
        <span>他社のサービスとの比較</span>
      </h2>
      <div className={classNames(Style.sub_catch)}>
        <span>他の主要カートと比較しても、始めやすい価格帯です</span>
      </div>

      <div className={classNames(Style.cmp__table)}>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>
                comachicart
                <br />
                スタンダードプラン
              </th>
              <th>
                国内サービス
                <br />B
              </th>
              <th>
                国内サービス
                <br />C
              </th>
              <th>
                海外サービス
                <br />S
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                カートシステム
                <br />
                初期費用
              </td>
              <td>0円</td>
              <td>0円</td>
              <td>3,300円</td>
              <td>0円</td>
            </tr>
            <tr>
              <td>
                カートシステム
                <br />
                月額費用
              </td>
              <td>0円</td>
              <td>0円</td>
              <td>4,900円</td>
              <td>0円</td>
            </tr>
            <tr>
              <td>
                カートシステム
                <br />
                システム利用料
                <br />
                (販売手数料)
              </td>
              <td>　3％<sup className={classNames(Style.fs11)}>※4</sup></td>
              <td>3％</td>
              <td>0円</td>
              <td>
                2％
                <br />
                <span className={classNames(Style.fs10)}>※Sの提供する決済サービスを利用していない場合</span>
              </td>
            </tr>
            <tr>
              <td>
                クレジットカード決済
                <br />
                月額費用
              </td>
              <td>0円</td>
              <td>0円</td>
              <td>
                法人：2,500円
                <br />
                個人：1,300円
                <br />
                <span className={classNames(Style.fs10)}>※月額最低手数料</span>
                <br />
                <span className={classNames(Style.fs10)}>（VISA/MASTER/DINERS）</span>
              </td>
              <td>0円</td>
            </tr>
            <tr>
              <td>
                クレジットカード決済
                <br />
                決済手数料
              </td>
              <td>　3.5%<sup className={classNames(Style.fs11)}>※5</sup></td>
              <td>3.6%</td>
              <td>
                法人：4～5％
                <br />
                個人：5.5%
              </td>
              <td>
                3.4％
                <br />
                4.15％<span className={classNames(Style.fs12)}>(JCBの場合）</span>
              </td>
            </tr>
            <tr>
              <td>
                売上処理量
                <br />
                <span className={classNames(Style.fs12)}>（トランザクション費用）</span>
              </td>
              <td>0円</td>
              <td>40円</td>
              <td>0円</td>
              <td>0円</td>
            </tr>
          </tbody>
        </table>
        <p className={classNames(Style.pt0, Style.pb0, Style.fs12)}>※4 システム利用料は【税込】価格となります。</p>
        <p className={classNames(Style.pt0, Style.pb0, Style.fs12)}>※5 決済手数料は【税抜】価格となります。</p>
      </div>
      <div className={classNames(Style.notice)}>
        <span className={Style.notice__icon}>
          <FontAwesomeIcon className={Style.notice__icon__inner} icon={
    faExclamationTriangle} />
        </span>
        <div className={classNames(Style.notice__text)}>
          <div>＊当社独自調査による比較となります（2022年11月1日時点）。</div>
          <div>＊使える機能やサービスは各社ごとに異なります。</div>
          <div>＊海外サービスは1ドル145円に換算して計算しています。</div>
        </div>
      </div>
    </section>
  );
};

export default Comparision;
